import { ICalloutFields } from 'contentful-types'
import { mapModal } from '@/siteBuilder/renderer/contentful/mapModal'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import Callout from './Callout'

export const linkColourMap = {
  // When value is 'default', value will be not be used and colour will default to UDS Link's default colour
  Default: 'default',
  // When value is 'alternative', value will be used to apply UDS Link's alternative colour
  Alternative: 'alternative',
  // When value is 'matchColour', value will be used to match the colour of the text which comes from the parent element
  'Match Colour': 'matchColour',
}

const ContentfulCallout = withContentful<ICalloutFields>(Callout, {
  size: (props) => props.fields.size?.toLowerCase() as Lowercase<ICalloutFields['size']>,
  iconVerticalAlign: (props) =>
    props.fields.iconVerticalAlign?.toLowerCase() as Lowercase<ICalloutFields['iconVerticalAlign']>,
  textAlign: (props) => props.fields.textAlign?.toLowerCase() as Lowercase<ICalloutFields['textAlign']>,
  contentTextAlign: (props) =>
    props.fields.contentTextAlign?.toLowerCase() as Lowercase<ICalloutFields['contentTextAlign']>,
  linkColour: (props) => linkColourMap[props.fields.linkColour],
  baseModal: mapModal,
})

export default ContentfulCallout
